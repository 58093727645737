var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BT-Blade-Item',{attrs:{"bladeName":"courier-price-tier","bladesData":_vm.bladesData,"loadingMsg":_vm.loadingMsg,"navigation":"courier-price-tiers","onGetSave":_vm.trimItemForSave,"onNew":function () { return { priceTierName: null, priceTierItems: [] } },"onPullSuccessAsync":_vm.pullItem,"title":"Courier Price Tier"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
var data = ref.data;
return [_c('BT-Field-String',{attrs:{"label":"Price Tier Name","isEditing":data.isEditing || data.isNew},model:{value:(item.priceTierName),callback:function ($$v) {_vm.$set(item, "priceTierName", $$v)},expression:"item.priceTierName"}}),_c('BT-Snack',{model:{value:(_vm.msg),callback:function ($$v) {_vm.msg=$$v},expression:"msg"}}),_c('v-subheader',[_vm._v("Price Items")]),_vm._l((item.priceTierItems),function(item,index){return _c('Price-Tier-Item',{key:index,attrs:{"item":item},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [(item.itemType === 'Weight')?_c('span',[_vm._v("Kilogram")]):(item.itemType === 'Volume')?_c('span',[_vm._v("Litre")]):(item.itemType === 'Distance')?_c('span',[_vm._v("Kilometre")]):(item.itemType === 'Zone')?_c('BT-Entity',{attrs:{"navigation":"zones","itemText":"zoneName","itemValue":item.itemID}}):(item.itemType === 'Measurement')?_c('BT-Entity',{attrs:{"navigation":"measurements","itemText":"measurementName","itemValue":item.itemID},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.measurementName)+" ")]}},{key:"alternate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.itemID)+" ")]}}],null,true)}):_vm._e()]}}],null,true)})}),_c('BT-Dialog',{attrs:{"block":"","label":"New Priced Item","text":"Add Priced Item","width":"400"},on:{"ok":_vm.addItem},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-slide-x-transition',{attrs:{"group":""}},[_c('BT-Select',{key:"1",attrs:{"label":"Line Type","itemText":"text","itemValue":"value","items":[
                        { text: 'Zone', value: 'Zone' },
                        { text: 'Kilogram', value: 'Weight' },
                        { text: 'Litre', value: 'Volume' }, 
                        { text: 'Package', value: 'Measurement' },
                        { text: 'Kilometre', value: 'Distance' }]},model:{value:(item.itemType),callback:function ($$v) {_vm.$set(item, "itemType", $$v)},expression:"item.itemType"}}),(item.itemType === 'Measurement')?_c('BT-Select',{key:"2",attrs:{"label":"Measurement","navigation":"measurements","itemText":"measurementName","itemValue":"id"},model:{value:(item.itemID),callback:function ($$v) {_vm.$set(item, "itemID", $$v)},expression:"item.itemID"}}):_vm._e(),(item.itemType === 'Zone')?_c('BT-Select',{key:"3",attrs:{"label":"Zone","navigation":"zones","itemText":"zoneName","itemValue":"id"},model:{value:(item.itemID),callback:function ($$v) {_vm.$set(item, "itemID", $$v)},expression:"item.itemID"}}):_vm._e()],1)]}}],null,true),model:{value:(_vm.dialogData),callback:function ($$v) {_vm.dialogData=$$v},expression:"dialogData"}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }