<template>
    <BT-Blade-Item
        bladeName="courier-price-tier"
        :bladesData="bladesData"
        :loadingMsg="loadingMsg"
        navigation="courier-price-tiers"
        :onGetSave="trimItemForSave"
        :onNew="() => { return { priceTierName: null, priceTierItems: [] } }"
        :onPullSuccessAsync="pullItem"
        title="Courier Price Tier">
        <template v-slot="{ item, data }">
            
            <BT-Field-String
                v-model="item.priceTierName"
                label="Price Tier Name"
                :isEditing="data.isEditing || data.isNew" />
                
            <BT-Snack v-model="msg" />

            <v-subheader>Price Items</v-subheader>
        
            <Price-Tier-Item 
                v-for="(item, index) in item.priceTierItems" 
                :item="item" 
                :key="index">
                <template v-slot="{ item }">
                    <span v-if="item.itemType === 'Weight'">Kilogram</span>
                    <span v-else-if="item.itemType === 'Volume'">Litre</span>
                    <span v-else-if="item.itemType === 'Distance'">Kilometre</span>
                    <BT-Entity
                        v-else-if="item.itemType === 'Zone'"
                        navigation="zones"
                        itemText="zoneName"
                        :itemValue="item.itemID" />
                    <BT-Entity
                        v-else-if="item.itemType === 'Measurement'"
                        navigation="measurements"
                        itemText="measurementName"
                        :itemValue="item.itemID">
                        <template slot-scope="{ item }">
                            {{ item.measurementName }}
                        </template>
                        <template slot="alternate" slot-scope="{ item }">
                            {{ item.itemID }}
                        </template>
                    </BT-Entity>
                </template>
            </Price-Tier-Item>

            <BT-Dialog
                v-model="dialogData"
                block
                label="New Priced Item"
                text="Add Priced Item"
                width="400"
                @ok="addItem">
                <template slot-scope="{ item }">
                    <v-slide-x-transition group>

                    <BT-Select
                        label="Line Type"
                        v-model="item.itemType"
                        itemText="text"
                        itemValue="value"
                        key="1"
                        :items="[
                            { text: 'Zone', value: 'Zone' },
                            { text: 'Kilogram', value: 'Weight' },
                            { text: 'Litre', value: 'Volume' }, 
                            { text: 'Package', value: 'Measurement' },
                            { text: 'Kilometre', value: 'Distance' }]" />

                    <BT-Select
                        v-if="item.itemType === 'Measurement'"
                        label="Measurement"
                        v-model="item.itemID"
                        navigation="measurements"
                        itemText="measurementName"
                        itemValue="id"
                        key="2" />

                    <BT-Select
                        v-if="item.itemType === 'Zone'"
                        label="Zone"
                        v-model="item.itemID"
                        navigation="zones"
                        itemText="zoneName"
                        itemValue="id"
                        key="3" />

                    </v-slide-x-transition>

                </template>
            </BT-Dialog>
        </template>
    </BT-Blade-Item>
</template>

<script>
import { firstBy } from 'thenby';

export default {
    name: 'Courier-Price-Tier',
    components: {
        PriceTierItem: () => import('~home/price-tiers/Price-Tier-Item.vue'),
        BTDialog: () => import('~components/BT-Dialog.vue')
    },
    data: function() {
        return {
            msg: null,
            currentTier: null,
            dialogData: {
                id: null,
                itemType: null,
                itemID: null,
                description: null,
                valueIncrements: [],
                rateFactor: 'unit',
                valuePerRateFactor: 0
            },
            loadingMsg: null
        }
    },
    props: {
        bladesData: null
    },
    methods: {
        addItem(item) {
            if (item.itemType == null || (item.itemType == 'Zone' || item.itemType == 'Measurement') && item.itemID == null) {                
                this.msg = "Try Again";
                return;
            }

            this.currentTier.priceTierItems.push(item);

            this.dialogData = {
                id: null,
                itemType: null,
                itemID: null,
                description: null,
                valueIncrements: [],
                rateFactor: 'unit',
                valuePerRateFactor: 0
            };
        },
        trimItemForSave(bladeData, item) {
            item.priceTierItems = item.priceTierItems.filter(x => x.valuePerRateFactor > 0 || (this.isLengthyArray(x.valueIncrements)));
            return item;
        },
        pullItem(item) {
            item.priceTierItems.sort(firstBy(x => x.itemType).thenBy(x => x.valuePerRateFactor).thenBy(x => x.description));
            this.currentTier = item;
            return item;
        }
    }
}
</script>